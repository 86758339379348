<template>
  <section id="404">
    <header>404</header>
    <body>Page non trouvée. &#127793;</body>
  </section>
</template>

<style lang="scss" scoped>
header {
  text-align:center;
  font-size: 1000%;
  font-weight: bold;
}

body {
  text-align: center;
  margin-bottom: 5rem;
}
</style>